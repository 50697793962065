import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import banner from "../../assets/banner_img2.jpg";
import banner3 from "../../assets/bannerimg3.webp";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { AiOutlineConsoleSql } from "react-icons/ai";
import {
  MdQueryStats,
  MdOutlineHighQuality,
  MdMarkEmailRead,
} from "react-icons/md";
import cs from "../../assets/content-media.gif";
import abm from "../../assets/abm.gif";
import bant from "../../assets/bant.png";
import email from "../../assets/mail.gif";
import { Link as ScrollLink } from "react-scroll";
import "../../App.css";

const Banner = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <Box position="relative">
      {/* Banner Section */}
      <Swiper
        spaceBetween={30}
        //pagination={{ clickable: true }}
        loop={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        // pagination={{
        //   clickable: true,
        // }}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        direction="horizontal"
        style={{ width: "100%", height: isMobile ? "50vh" : "75vh" }}
      >
        <SwiperSlide>
          <Box position="relative" height="100%" overflow="hidden">
            {/* Background Image with Animation */}
            <Box
              backgroundImage={`url(${banner})`}
              backgroundSize="cover"
              backgroundPosition="left center"
              height="100%"
              width="100%"
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              zIndex="0"
              animation="bgMoveZoom 20s ease-in-out infinite"
            ></Box>

            {/* Content Section */}
            <Flex
              justify="flex-start"
              height="100%"
              bg="rgba(0, 0, 0, 0.4)"
              color="white"
              p={isMobile ? 4 : 8}
              pt={isMobile ? 10 : 20}
              pl={isMobile ? 10 : 60}
              position="relative"
              zIndex="1"
            >
              <VStack spacing={1} align="flex-start">
                <Text fontSize={isMobile ? "2xl" : "6xl"} fontWeight="bold">
                  <span
                    className="lavishly-yours-regular"
                    style={{
                      fontSize: isMobile ? "60px" : "120px",
                      color: "#FDEF68",
                    }}
                  >
                    Explore
                  </span>{" "}
                  <span style={{ filter: "drop-shadow(1px 1px 8px #E9BD36)" }}>
                    B2B Leads
                  </span>
                </Text>

                <Text
                  maxW="3xl"
                  fontSize={isMobile ? "md" : "xl"}
                  textAlign="left"
                  className="lumanosimo-regular"
                  position="relative"
                  top={-10}
                  left={150}
                >
                  We find the right audience for your future sales.
                </Text>

                <ScrollLink to="contact-us" smooth={true} spy={true} >
                  <Link fontSize={["sm", "md", "lg"]} fontWeight={"bold"}>
                    <Button bg="#FDEF68" size="sm" _hover={{ bg: "#f0c40d" }}
                     position="relative"
                     top={-10}
                     left={"620%"}
                    >
                      Get Started
                    </Button>
                  </Link>
                </ScrollLink>
              </VStack>
            </Flex>

            <style>
              {`
        @keyframes bgMoveZoom {
          0% {
            background-position: left center;
            transform: scale(1);
          }
          50% {
            background-position: center center;
            transform: scale(1.1);
          }
          100% {
            background-position: right center;
            transform: scale(1);
          }
        }
        div[style*="backgroundImage"] {
          animation: bgMoveZoom 20s ease-in-out infinite;
          background-size: cover;
          background-position: left center;
          will-change: background-position, transform;
        }
      `}
            </style>
          </Box>
        </SwiperSlide>

        <SwiperSlide>
          <Box
            backgroundImage={`url(${banner3})`}
            backgroundSize="cover"
            backgroundPosition="left center"
            height="100%"
            position="relative"
            overflow="hidden"
            _before={{
              content: `""`,
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${banner3})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "left center",
              animation: "bgMoveZoom 30s ease-in-out infinite",
              zIndex: 0,
            }}
          >
            <Flex
              justify="flex-start"
              height="100%"
              bg="rgba(0, 0, 0, 0.4)"
              color="white"
              p={isMobile ? 4 : 8}
              pt={isMobile ? 4 : 20}
              pl={isMobile ? 4 : 60}
              position="relative"
              zIndex="1"
            >
              <VStack spacing={1} align="flex-start">
                <Text fontSize={isMobile ? "2xl" : "6xl"} fontWeight="bold">
                  <span
                    className="lavishly-yours-regular"
                    style={{ fontSize: "120px", color: "#FDEF68" }}
                  >
                    Explore
                  </span>{" "}
                  <span style={{ filter: "drop-shadow(1px 1px 8px #E9BD36)" }}>
                    B2B Leads
                  </span>
                </Text>

                <Text
                  maxW="3xl"
                  fontSize={isMobile ? "md" : "xl"}
                  textAlign="left"
                  className="lumanosimo-regular"
                  position="relative"
                  top={-10}
                  left={150}
                  
                >
                  We find the right audience for your future sales.
                </Text>

                <ScrollLink to="contact-us" smooth={true} spy={true} >
                  <Link fontSize={["sm", "md", "lg"]} fontWeight={"bold"}>
                    <Button bg="#FDEF68" size="sm" _hover={{ bg: "#f0c40d" }}
                     position="relative"
                     top={-10}
                     left={"620%"}
                    >
                      Get Started
                    </Button>
                  </Link>
                </ScrollLink>
              </VStack>
            </Flex>

            <style>
              {`
        @keyframes bgMoveZoom {
          0% {
            background-position: left center;
            transform: scale(1);
          }
          50% {
            background-position: center center;
            transform: scale(1.1);
          }
          100% {
            background-position: right center;
            transform: scale(1);
          }
        }
      `}
            </style>
          </Box>
        </SwiperSlide>
        {/* <SwiperSlide>
          <Box
            backgroundImage={`url(${banner})`}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="left top"
            height="100%"
            position="relative"
            overflow="hidden"
            _before={{
              content: `""`,
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${banner})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "left top",
              animation: "bgMoveZoom 30s ease-in-out infinite",
              zIndex: 0,
            }}
          >
            <Flex
              justify="flex-start"
              height="100%"
              bg="rgba(0, 0, 0, 0.4)"
              color="white"
              p={isMobile ? 4 : 8}
              pt={isMobile ? 4 : 20}
              pl={isMobile ? 4 : 60}
              position="relative"
              zIndex="1"
            >
              <VStack spacing={1} align="flex-start">
                <Text fontSize={isMobile ? "2xl" : "6xl"} fontWeight="bold">
                  <span
                    className="lavishly-yours-regular"
                    style={{ fontSize: "120px", color: "#FDEF68" }}
                  >
                    Explore
                  </span>{" "}
                  <span style={{ filter: "drop-shadow(1px 1px 8px #E9BD36)" }}>
                    B2B Leads
                  </span>
                </Text>

                <Text
                  maxW="3xl"
                  fontSize={isMobile ? "md" : "2xl"}
                  textAlign="left"
                  className="lumanosimo-regular"
                >
                  We find the right audience for your future sales.
                </Text>

                <ScrollLink to="contact-us" smooth={true} spy={true}>
                  <Link fontSize={["sm", "md", "lg"]} fontWeight={"bold"}>
                    <Button bg="#FDEF68" size="lg" _hover={{ bg: "#f0c40d" }}>
                      Get Started
                    </Button>
                  </Link>
                </ScrollLink>
              </VStack>
            </Flex>

            <style>
              {`
        @keyframes bgMoveZoom {
          0% {
            background-position: left top;
            transform: scale(1);
          }
          25% {
            background-position: right top;
            transform: scale(1.1);
          }
          50% {
            background-position: right bottom;
            transform: scale(1);
          }
          75% {
            background-position: left bottom;
            transform: scale(1.1);
          }
          100% {
            background-position: left top;
            transform: scale(1);
          }
        }
      `}
            </style>
          </Box>
        </SwiperSlide> */}
      </Swiper>

      {/* Black Box Section */}
      <Box
        position="absolute"
        left="50%"
        transform="translateX(-50%)"
        width={{ base: "95%", md: "85%", lg: "75%" }}
        bg="black"
        color="white"
        zIndex={5}
        boxShadow="2xl"
        borderRadius={20}
        top="90%"
        py={3}
        px={8}
        //role="group"
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
          align="center"
        >
          <Text
            fontSize={{ base: "sm", md: "lg", lg: "lg" }}
            fontWeight="bold"
            textAlign={{ base: "center", md: "left" }}
            w={{ base: "100%", md: "40%" }}
            mb={{ base: 4, md: 0 }}
          >
            YOUR TRUSTED PLATFORM <br />
            FOR QUALIFIED LEADS
          </Text>

          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            breakpoints={{
              640: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 4 },
            }}
            pagination={{ clickable: true }}
            //navigation={{ clickable: true }}
            style={{ width: "60%" }}
          >
            {[
              {
                icon: <AiOutlineConsoleSql />,
                image: cs,
                heading: "Content Syndication",
                text: "We offer CPL content syndication, Whitepapers campaigns including MQLs, SQLs, and HQLs.",
              },
              {
                icon: <MdQueryStats />,
                image: abm,
                heading: "ABM",
                text: "We specialize in ABM, providing tailored lead generation strategies that engage high-value accounts.",
              },
              {
                icon: <MdOutlineHighQuality />,
                image: bant,
                heading: "BANT",
                text: "BANT, ANUM, and CHAMP frameworks qualify leads precisely, directing your team to top opportunities.",
              },
              {
                icon: <MdMarkEmailRead />,
                image: email,
                heading: "Email Marketing",
                text: "We create tailored email campaigns using metrics and opt-in lists for high-impact engagement and CTAs.",
              },
            ].map((slide, index) => (
              <SwiperSlide key={index}>
                <Box
                  //bg="#f0c40d"
                  // bg="#E9BD36"
                  bg="#FDEF68"
                  borderRadius="3xl"
                  textAlign="center"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  _hover={{
                    //transform: "scale(1.15)",
                    transform: "rotateY(360deg)",
                    boxShadow: "xl",
                    bg: "white",
                    color: "black",
                    borderRadius: "3xl",
                    //transition: "transform 0.2s",
                    transition: "transform 0.2s",
                    transformStyle: "preserve-3d",
                  }}
                  style={{
                    minHeight: hoveredIndex === index ? "16vh" : "16vh",
                    //transition: "transform 0.3s ease-out",
                    //overflow: "hidden",
                    perspective: "1000px",
                    //transformStyle: "preserve-3d",
                    transition: "transform 0.6s ease, box-shadow 0.4s ease",
                  }}
                >
                  {hoveredIndex === index ? (
                    <>
                      <Text
                        mt={1}
                        fontSize={{ base: "xs", md: "sm", lg: "2xs" }}
                      >
                        {slide.text}
                      </Text>
                    </>
                  ) : (
                    <>
                      <Box
                        mb={2}
                        style={{
                          backfaceVisibility: "hidden",
                        }}
                      >
                        <Image
                          src={slide.image}
                          alt="custom"
                          boxSize="40px"
                          objectFit="cover"
                          mixBlendMode="multiply"
                          transition="transform 0.3s ease-in-out"
                        />
                      </Box>
                      <Heading
                        fontSize={{ base: "sm", md: "md", lg: "md" }}
                        color={"black"}
                      >
                        {slide.heading}
                      </Heading>
                    </>
                  )}
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Flex>
      </Box>
    </Box>
  );
};

export default Banner;
