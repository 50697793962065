import {
  Box,
  Flex,
  VStack,
  FormControl,
  Input,
  Textarea,
  Button,
  Text,
  Icon,
  Divider,
  Heading,
} from "@chakra-ui/react";
import { MdLocationOn, MdEmail, MdPhone, MdLanguage } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import wavyyellow from "../../assets/wavyimageyellow.png";
const ContactUs = () => {
  return (
    <Box
      position="relative"
      minH="100vh"
      overflow="hidden"
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundImage: `url(${wavyyellow})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        zIndex: "-1",
      }}
    >
      {/* Centered Heading */}
      <Heading
        fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
        textAlign="center"
        position="absolute"
        top="5%" // Adjust this value to move the heading up/down as needed
        left="50%"
        transform="translateX(-50%)"
        zIndex="2"
        color="black"
        
      >
        Contact Us | Explore Our Media Kit
      </Heading>

      {/* Flex Container for Contact Details and Form */}
      <Flex
        direction={{ base: "column", md: "row" }}
        align="center"
        justify="space-between"
        p={{ base: 6, md: 12 }}
        minH="100vh"
        position="relative"
        zIndex="1"
      >
        {/* Left Side: Contact Info */}
        <Box
          bg="black"
          color="white"
          borderRadius="3xl"
          p={8}
          width={{ base: "100%", md: "25%" }}
          textAlign="left"
          position="relative"
          zIndex="1"
          ml={{ base: 0, md: 250 }}
        >
          <Text fontSize="2xl" fontWeight="bold" mb={8}>
            Contact Us
          </Text>
          <VStack align="start" spacing={4}>
            <Flex align="center">
              <Icon as={MdLocationOn} w={6} h={6} color="#FDEF68" mr={4} />
              <Text>13000 S Tryon St, Charlotte, NC 28278</Text>
            </Flex>
            <Divider borderColor="white" borderStyle="dashed" />
            <Flex align="center">
              <Icon as={MdPhone} w={6} h={6} color="#FDEF68" mr={4} />
              <Text>+1 980 224 8664</Text>
            </Flex>
            <Divider borderColor="white" borderStyle="dashed" />
            <Flex align="center">
              <Icon as={MdEmail} w={6} h={6} color="#FDEF68" mr={4} />
              <Text>info@exploreb2blead.com</Text>
            </Flex>
            <Divider borderColor="white" borderStyle="dashed" />
            <Flex align="center">
              <Icon as={MdLanguage} w={6} h={6} color="#FDEF68" mr={4} />
              <Text>www.exploreb2blead.com</Text>
            </Flex>
            <Divider borderColor="white" borderStyle="dashed" />
          </VStack>
        </Box>

        {/* Right Side: Contact Form */}
        <Box
          bg="white"
          p={8}
          borderRadius="lg"
          boxShadow="xl"
          width={{ base: "100%", md: "80%" }}
          mx="auto"
          zIndex="1"
          mr={{base: 0, md: 250}}
          mt={{base: 10, md: 0}}
        >
          <VStack spacing={6} align="stretch">
            <Flex direction={{ base: "column", md: "row" }} gap={4}>
              <FormControl isRequired>
                <Input
                  placeholder="First Name"
                  bg="white"
                  _placeholder={{ color: "gray.500" }}
                  borderRadius="md"
                  size="lg"
                  focusBorderColor="yellow.400"
                  boxShadow={"md"}
                />
              </FormControl>
              <FormControl isRequired>
                <Input
                  placeholder="Last Name"
                  bg="white"
                  _placeholder={{ color: "gray.500" }}
                  borderRadius="md"
                  size="lg"
                  focusBorderColor="yellow.400"
                  boxShadow={"md"}
                />
              </FormControl>
            </Flex>

            <Flex direction={{ base: "column", md: "row" }} gap={4}>
              <FormControl isRequired>
                <Input
                  type="email"
                  placeholder="Your Email"
                  bg="white"
                  _placeholder={{ color: "gray.500" }}
                  borderRadius="md"
                  size="lg"
                  focusBorderColor="yellow.400"
                  boxShadow={"md"}
                />
              </FormControl>
              <FormControl isRequired>
                <PhoneInput
                  country={"us"}
                  placeholder="Enter phone number"
                  inputStyle={{
                    width: "100%",
                    height: "45px",
                    borderRadius: "8px",
                    border: "1px solid #E2E8F0",
                    boxShadow: "inherit",
                  }}
                />
              </FormControl>
            </Flex>

            <FormControl isRequired>
              <Input
                placeholder="Subject"
                bg="white"
                _placeholder={{ color: "gray.500" }}
                borderRadius="md"
                size="lg"
                focusBorderColor="yellow.400"
                boxShadow={"md"}
              />
            </FormControl>

            <FormControl isRequired>
              <Textarea
                placeholder="Your message..."
                bg="white"
                _placeholder={{ color: "gray.500" }}
                borderRadius="md"
                size="lg"
                rows={2}
                focusBorderColor="yellow.400"
                boxShadow={"md"}
              />
            </FormControl>

            <Button
              mt={8}
              width="100%"
              bg="black"
              color="#f0c40d"
              size="lg"
              _hover={{ bg: "yellow.500", color: "black" }}
            >
              Submit
            </Button>
          </VStack>
        </Box>
      </Flex>
    </Box>
  );
};

export default ContactUs;
