import React from 'react'
import Navbar from '../../Components/Navbar'
import Home from './Home'
import Footer from '../../Components/Footer'

const MainPage = () => {
  return (
    <div>
          <Navbar />
          <Home />
          <Footer />
    </div>
  )
}

export default MainPage