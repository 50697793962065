import React from "react";
import AboutUs from "./AboutUs";
import Services from "./Services";
import OurProcess from "./OurProcess";
import ContactUs from "./ContactUs";
import Gdpr from "./Gdpr";
import Banner from "./Banner";

const Home = () => {
  return (
    <>
      <section id="banner">
        <Banner />
      </section>
      <section id="about-us">
        <AboutUs />
      </section>
      <section id="services">
        <Services />
      </section>
      <section id="our-process">
        <OurProcess />
      </section>
      <section id="gdpr">
        <Gdpr />
      </section>
      <section id="contact-us">
        <ContactUs />
      </section>
    </>
  );
};

export default Home;
