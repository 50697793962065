import React from "react";
import {
  Box,
  Flex,
  VStack,
  HStack,
  Text,
  Link,
  Icon,
  Divider,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  FaInstagram,
  FaLinkedin,
  FaHome,
  FaPhoneAlt,
  FaFirefox,
} from "react-icons/fa";
import logo from "../assets/eanddyellowtag.png"; // Replace with your logo path
import { Link as ScrollLink } from "react-scroll";
import footerbg from "../assets/footerbg.jpg";
import footerbg2 from "../assets/footerbg2.JPG";
import footerbg3 from "../assets/footerbg3.png";
const Footer = () => {
  const flexDirection = useBreakpointValue({ base: "column", md: "row" });

  return (
    <Box
      py={10}
      px={5}
      backgroundImage={`url(${footerbg3})`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="center"
      position="relative"
      zIndex={0} // Keeps the background image below the content
    >
      {/* Overlay with reduced opacity */}
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        backgroundColor="rgba(0, 0, 0, 0.5)" // Adjust opacity value here
        zIndex={-1} // Ensures the overlay stays behind the content
      />

      {/* Footer Content */}
      <Flex
        justifyContent="space-evenly"
        flexDirection={flexDirection}
        alignItems={flexDirection === "row" ? "flex-start" : "center"}
        zIndex={1} // Ensures content stays on top of the overlay
      >
        {/* Logo and Description */}
        <VStack align={["center", "flex-start"]} mb={5} color={"white"}>
          {/* <Box
            as="img"
            src={logo}
            alt="Logo"
            mb={4}
            w={300}
            display="block"
            marginLeft={0}
          /> */}
          {/* <Box
            position="relative"
            display="inline-block"
            p={2} // Padding around the image
            bg="white" // White background
            borderRadius="md" // Optional: rounded corners
          >
            <Box as="img" src={logo} alt="Logo" w={300} display="block" />
          </Box> */}
          {/* <Box
            as="img"
            src={logo}
            alt="Logo"
            mb={4}
            w={300}
            display="block"
            style={{
              filter: "drop-shadow(0px 0px 4px white)",
            }}
          /> */}
          <Box position="relative" display="inline-block">
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              bg="rgba(255, 255, 255, 0.2)" 
              borderRadius="md"
              zIndex={-1} 
            />
            <Box as="img" src={logo} alt="Logo" w={300} display="block" />
          </Box>

          <Text fontSize="sm" textAlign={["center", "left"]}>
            Partner with us to turn your demand generation <br /> challenges
            into growth opportunities. <br /> Let’s explore the possibilities
            together!
          </Text>
          {/* Social Icons */}
          <HStack spacing={3} mt={4}>
            <Link
              href="https://www.linkedin.com/company/explore-and-do-technologies-pvt-ltd/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon as={FaLinkedin} boxSize={10} />
            </Link>
            <Link
              href="https://www.instagram.com/exploreanddo/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon as={FaInstagram} boxSize={10} />
            </Link>
          </HStack>
        </VStack>

        {/* Footer Links */}
        <HStack
          spacing={8}
          mb={5}
          align="flex-start"
          flexDirection={flexDirection === "row" ? "row" : "column"}
          color={"white"}
        >
          {/* Company Section */}
          <VStack align="flex-start">
            <Text fontWeight="bold" textDecoration={"underline"}>
              Services
            </Text>
            <ScrollLink to="services" smooth={true} spy={true} offset={200}>
              <Link>Our Database</Link>
            </ScrollLink>
            <ScrollLink to="services" smooth={true} spy={true} offset={200}>
              <Link>Lead Generation</Link>
            </ScrollLink>
            <ScrollLink to="services" smooth={true} spy={true} offset={200}>
              <Link>Content Syndication</Link>
            </ScrollLink>
            <ScrollLink to="services" smooth={true} spy={true} offset={550}>
              <Link>Account Based Marketing</Link>
            </ScrollLink>
            <ScrollLink to="services" smooth={true} spy={true} offset={550}>
              <Link>BANT</Link>
            </ScrollLink>
            <ScrollLink to="services" smooth={true} spy={true} offset={550}>
              <Link>Email Marketing</Link>
            </ScrollLink>
            <HStack py={5}>
              <Icon as={FaFirefox} />{" "}
              <Text fontWeight="semibold">info@exploreb2blead.com</Text>
            </HStack>
          </VStack>
        </HStack>

        <VStack align="flex-start" spacing={4} mb={5} color={"white"}>
          <VStack align="flex-start" spacing={1}>
            <Text fontWeight="bold" textDecoration={"underline"}>
              India Office
            </Text>
            <HStack>
              <Icon as={FaHome} />{" "}
              <Text fontWeight="semibold">4-3-116/1, 1st & 2nd Floor,</Text>
            </HStack>
            <Text fontWeight="semibold">Wase Arcade, Pillar No-123,</Text>
            <Text fontWeight="semibold">Attapur, Hyderabad, India</Text>
            {/* <Text fontWeight="semibold">Hyderabad, Telangana,</Text>
          <Text fontWeight="semibold">500048-India</Text> */}
            {/* <VStack align="flex-start" spacing={1} mt={4}>
            <HStack>
              <Icon as={FaFirefox} />{" "}
              <Text fontWeight="semibold">info@exploreb2blead.com</Text>
            </HStack>
          </VStack> */}
            <VStack align="flex-start" spacing={1} mt={4}>
              <HStack>
                <Icon as={FaPhoneAlt} />{" "}
                <Text fontWeight="semibold">+91 799-581-6886</Text>
              </HStack>
              <HStack>
                <Icon as={FaPhoneAlt} />{" "}
                <Text fontWeight="semibold">+91 810-601-6337</Text>
              </HStack>
            </VStack>
          </VStack>
        </VStack>

        <VStack align="flex-start" spacing={4} mb={5} color={"white"}>
          <VStack align="flex-start" spacing={1}>
            <Text fontWeight="bold" textDecoration={"underline"}>
              US Office
            </Text>
            <HStack>
              <Icon as={FaHome} />{" "}
              <Text fontWeight="semibold">13000 S Tryon St,</Text>
            </HStack>
            <Text fontWeight="semibold">Charlotte, NC 28278</Text>
            <Text fontWeight="semibold">United States</Text>
            <VStack align="flex-start" spacing={1} mt={4}></VStack>
            <VStack align="flex-start" spacing={1} mt={4}>
              <HStack>
                <Icon as={FaPhoneAlt} />{" "}
                <Text fontWeight="semibold">+1 980 224 8664</Text>
              </HStack>
            </VStack>
          </VStack>
        </VStack>
      </Flex>

      {/* Footer Divider */}
      {/* <Divider my={6} /> */}

      {/* Copyright Section */}
      <Text fontSize="sm" textAlign="center" color="white" my={2}>
        © Copyrights exploreanddo.com 2022. All rights reserved.
      </Text>
    </Box>
  );
};

export default Footer;
