import React from "react";
import { Route, Routes } from "react-router-dom";
import MainPage from "../Pages/LeadManagement/MainPage";
const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
    </Routes>
  );
};

export default AllRoutes;
