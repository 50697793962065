import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  HStack,
  Link,
  Text,
  Icon,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  VStack,
  IconButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { PhoneIcon, HamburgerIcon, EmailIcon } from "@chakra-ui/icons";
import { Link as ScrollLink } from "react-scroll";
import logo from "../assets/eanddyellowtag.png";
import { FaInstagram, FaLinkedin } from "react-icons/fa";

const Navbar = () => {
  const [isSticky, setSticky] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSetActive = (to) => {
    setActiveSection(to);
  };

  return (
    <Box position="relative" zIndex={999}>
      <Flex
        bg="#FAF9F6"
        justifyContent="space-between"
        alignItems="center"
        p={4}
        position="relative"
        //zIndex={10}
      >
        {isMobile ? (
          ""
        ) : (
          <HStack spacing={0}>
            <Box as="img" src={logo} alt="logo" w={280} />
          </HStack>
        )}

        {/* <VStack spacing={1} display={["none", "none", "flex"]}>
          <HStack fontWeight={"bold"} alignSelf={"flex-start"}>
            <Icon as={EmailIcon} color={"#FDEF68"} />
            <Text fontSize={["sm", "md", "lg"]}>info@exploreb2blead.com</Text>
          </HStack>
          <HStack fontWeight={"bold"} alignSelf={"flex-start"}>
            <Icon as={PhoneIcon} color={"#FDEF68"} />
            <Text fontSize={["sm", "md", "lg"]}>+1 980 224 8664</Text>
          </HStack>
        </VStack> */}
        {/* 
        <HStack spacing={4}>
          <Link href="https://www.linkedin.com" isExternal>
            <Icon as={FaLinkedin} w={6} h={6} color="#0077b5" />
          </Link>
          <Link href="https://www.instagram.com" isExternal>
            <Icon as={FaInstagram} w={6} h={6} color="#E4405F" />
          </Link>
        </HStack> */}
        <HStack spacing={3} mt={4} mr={10}>
          <Link
            href="https://www.linkedin.com/company/explore-and-do-technologies-pvt-ltd/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon as={FaLinkedin} boxSize={10} color="#0077b5" />
          </Link>
          <Link
            href="https://www.instagram.com/exploreanddo/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon as={FaInstagram} boxSize={10} color="#E4405F" />
          </Link>
        </HStack>

        <IconButton
          aria-label="Open Menu"
          icon={<HamburgerIcon />}
          display={["flex", "flex", "none"]}
          onClick={onOpen}
        />
      </Flex>
      {isMobile ? (
        <VStack
          position={isSticky ? "fixed" : "absolute"}
          top={isSticky ? 0 : "10px"}
          left="50%"
          transform="translateX(-50%)"
          // width="62%"
          // bg="white"
          color="black"
          zIndex={5}
          boxShadow={isSticky ? "md" : "none"}
          transition="top 0.3s ease-in-out"
          borderRadius={10}
        >
          <HStack fontWeight={"bold"} alignSelf={"flex-start"} spacing={2}>
            <Icon as={EmailIcon} color={"#FFB600"} />
            <Text fontSize={["sm", "md", "lg"]}>info@exploreb2blead.com</Text>
          </HStack>
          <HStack fontWeight={"bold"} alignSelf={"flex-start"}>
            <Icon as={PhoneIcon} color={"#FFB600"} />
            <Text fontSize={["sm", "md", "lg"]}>+1 980 224 8664</Text>
          </HStack>
        </VStack>
      ) : (
        <Flex>
          <HStack
            position={isSticky ? "fixed" : "absolute"}
            top={isSticky ? 0 : "15px"}
            left="50%"
            transform="translateX(-50%)"
            // width="62%"
            // bg="white"
            color="black"
            zIndex={5}
            boxShadow={isSticky ? "md" : "none"}
            transition="top 0.3s ease-in-out"
            borderRadius={10}
          >
            <HStack fontWeight={"bold"} alignSelf={"flex-start"} >
              <Icon as={EmailIcon} color={"#FFB600"} />
              <Text fontSize={["sm", "md", "lg"]}>info@exploreb2blead.com</Text>
            </HStack>
            <HStack fontWeight={"bold"} alignSelf={"flex-start"}>
              <Icon as={PhoneIcon} color={"#FFB600"} />
              <Text fontSize={["sm", "md", "lg"]}>+1 980 224 8664</Text>
            </HStack>
          </HStack>
          <Box
            as="nav"
            position={isSticky ? "fixed" : "absolute"}
            top={isSticky ? 0 : "70px"}
            left="50%"
            transform="translateX(-50%)"
            width="62%"
            bg="black"
            color="white"
            zIndex={5}
            boxShadow={isSticky ? "md" : "none"}
            transition="top 0.3s ease-in-out"
            borderRadius={10}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              height="100%"
              py={4}
            >
              <HStack spacing={20} display={["none", "none", "flex"]}>
                <ScrollLink
                  to="about-us"
                  smooth={true}
                  spy={true}
                  onSetActive={handleSetActive}
                >
                  <Link
                    fontSize={["sm", "md", "lg"]}
                    fontWeight={"bold"}
                    color={activeSection === "about-us" ? "#FDEF68" : "white"}
                  >
                    About Us
                  </Link>
                </ScrollLink>
                <ScrollLink
                  to="services"
                  smooth={true}
                  spy={true}
                  onSetActive={handleSetActive}
                >
                  <Link
                    fontSize={["sm", "md", "lg"]}
                    fontWeight={"bold"}
                    color={activeSection === "services" ? "#FDEF68" : "white"}
                  >
                    Services
                  </Link>
                </ScrollLink>
                <ScrollLink
                  to="our-process"
                  smooth={true}
                  spy={true}
                  onSetActive={handleSetActive}
                >
                  <Link
                    fontSize={["sm", "md", "lg"]}
                    fontWeight={"bold"}
                    color={
                      activeSection === "our-process" ? "#FDEF68" : "white"
                    }
                  >
                    Our Process
                  </Link>
                </ScrollLink>
                <ScrollLink
                  to="gdpr"
                  smooth={true}
                  spy={true}
                  onSetActive={handleSetActive}
                >
                  <Link
                    fontSize={["sm", "md", "lg"]}
                    fontWeight={"bold"}
                    color={activeSection === "gdpr" ? "#FDEF68" : "white"}
                  >
                    GDPR
                  </Link>
                </ScrollLink>
                <ScrollLink
                  to="contact-us"
                  smooth={true}
                  spy={true}
                  onSetActive={handleSetActive}
                >
                  <Link
                    fontSize={["sm", "md", "lg"]}
                    fontWeight={"bold"}
                    color={activeSection === "contact-us" ? "#FDEF68" : "white"}
                  >
                    Contact Us
                  </Link>
                </ScrollLink>
              </HStack>
            </Flex>

            <Box bg="#FDEF68" height="5px" width="100%" borderRadius={10} />
          </Box>
        </Flex>
      )}

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <VStack spacing={6} align="start">
              <HStack spacing={0}>
                <Box as="img" src={logo} alt="logo" w={280} />
              </HStack>
              <ScrollLink
                to="about-us"
                smooth={true}
                spy={true}
                onSetActive={handleSetActive}
              >
                <Link
                  fontSize="lg"
                  color={activeSection === "about-us" ? "#f0c40d" : "black"}
                >
                  About Us
                </Link>
              </ScrollLink>
              <ScrollLink
                to="services"
                smooth={true}
                spy={true}
                onSetActive={handleSetActive}
              >
                <Link
                  fontSize="lg"
                  color={activeSection === "services" ? "#f0c40d" : "black"}
                >
                  Services
                </Link>
              </ScrollLink>
              <ScrollLink
                to="our-process"
                smooth={true}
                spy={true}
                onSetActive={handleSetActive}
              >
                <Link
                  fontSize="lg"
                  color={activeSection === "our-process" ? "#f0c40d" : "black"}
                >
                  Our Process
                </Link>
              </ScrollLink>
              <ScrollLink
                to="gdpr"
                smooth={true}
                spy={true}
                onSetActive={handleSetActive}
              >
                <Link
                  fontSize="lg"
                  color={activeSection === "gdpr" ? "#f0c40d" : "black"}
                >
                  GDPR
                </Link>
              </ScrollLink>
              <ScrollLink
                to="contact-us"
                smooth={true}
                spy={true}
                onSetActive={handleSetActive}
              >
                <Link
                  fontSize="lg"
                  color={activeSection === "contact-us" ? "#f0c40d" : "black"}
                >
                  Contact Us
                </Link>
              </ScrollLink>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Navbar;
