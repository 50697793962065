import {
  Box,
  Text,
  Heading,
  Flex,
  Stack,
  InputGroup,
  InputLeftAddon,
  Input,
  InputRightElement,
  Button,
  Circle,
  useBreakpointValue,
} from "@chakra-ui/react";

import React from "react";

const OurProcess = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const arrowColors = ["#FBCCB2", "#BFE3B3", "#7FFFD6"];
  const ProcessPhase = ({ number, title, description, color }) => {
    return (
      <Flex
        direction="column"
        align="center"
        justify="start"
        w="100%"
        maxW="320px"
        position="relative"
        textAlign="center"
        mb={{ base: 12, md: 0 }}
      >
        <Circle
          size="150px"
          bg={color}
          color="black"
          fontSize="24px"
          fontWeight="bold"
          position="relative"
          zIndex="1"
          mb="-75px"
          borderWidth={20}
          borderColor={"white"}
          boxShadow="0px 10px 20px -5px rgba(0, 0, 0, 0.3)"
        >
          {number}
        </Circle>

        <Box
          bg={color}
          borderRadius="lg"
          pt="60px"
          pb="8"
          px="6"
          w="100%"
          minH="320px"
          boxShadow="2xl"
          color="white"
          position="relative"
          //animation="shadowMove 20s ease-in-out infinite"
          _hover={{
            //animation: "shadowMove 10s ease-in-out infinite",
            transform: "scale(1.05)",
          }}
        >
          <style>
            {`
      @keyframes shadowMove {
        0% {
          box-shadow: -10px 10px 20px rgba(0, 0, 0, 0.2);
        }
        50% {
          box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.4);
        }
        100% {
          box-shadow: -10px 10px 20px rgba(0, 0, 0, 0.2);
        }
      }
    `}
          </style>
          {/* Circle overlaps box, giving a dipped effect */}
          <Box
            position="absolute"
            top="-40px"
            left="50%"
            transform="translateX(-50%)"
            bg={color}
            w="80px"
            h="40px"
            borderTopRadius="full"
            zIndex="0"
          />

          <Text
            fontWeight="bold"
            fontSize={{ base: "sm", md: "md", lg: "md" }}
            mb="4"
            mt={5}
            color="black"
          >
            {title}
          </Text>
          <Text
            fontSize={{ base: "xs", md: "sm", lg: "sm" }}
            color="black"
            textAlign={"justify"}
          >
            {description}
          </Text>
        </Box>
        {isMobile ? (
          ""
        ) : (
          <>
            {number !== "04" && (
              <Box
                position="absolute"
                right={{ base: "-60px", md: "-110px" }}
                top="10%"
                transform="translateY(-50%) rotate(-5deg)"
                zIndex="0"
              >
                <svg
                  width="150"
                  height="150"
                  viewBox="0 0 120 120"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0,50 C30,10 60,10 100,50"
                    //stroke="gray"
                    stroke={arrowColors[parseInt(number) - 1]}
                    strokeWidth="2"
                    fill="none"
                    markerEnd="url(#arrowhead)"
                  />
                  <defs>
                    <marker
                      id="arrowhead"
                      markerWidth="10"
                      markerHeight="7"
                      refX="0"
                      refY="3.5"
                      orient="auto"
                    >
                      <polygon
                        points="0 0, 10 3.5, 0 7"
                        // fill={arrowColors[parseInt(number) - 1]}
                        fill="black"
                      />
                    </marker>
                  </defs>
                </svg>
              </Box>
            )}
          </>
        )}
      </Flex>
    );
  };
  return (
    <Box py={20}>
      {/* <Heading
        textAlign="center"
        mb={8}
        fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
        // filter="drop-shadow(1px 1px 8px #f0c40d)"
      >
        Our Process
      </Heading> */}
      <Heading fontSize={{ base: "2xl", md: "3xl", lg: "5xl" }} mb={8}>
        Our Process
      </Heading>
      <Box position="relative" mt="-40px" ml="40px">
        <svg
          width="95%"
          height="50"
          viewBox="0 5 400 80"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 35 Q250 -15 500 30"
            fill="none"
            stroke="#f0c40d"
            strokeWidth="3"
          />
        </svg>
      </Box>
      {/* <Heading
        fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
        textAlign="center"
        mb={6}
        mt={12}
        position="relative"
        _hover={{
          "&::after": {
            transform: "scaleX(1)",
          },
        }}
        sx={{
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: "-5px",
            left: "40%",
            transform: "translateX(-50%) scaleX(0)",
            width: "20%",
            height: "4px",
            backgroundColor: "#FDEF68", // Neon color
            borderRadius: "2px",
            boxShadow: "0 0 8px #FDEF68, 0 0 15px #f0c40d",
            transformOrigin: "center",
            transition: "transform 0.3s ease-in-out",
          },
        }}
      >
        Our Process
      </Heading> */}
      {/* <Heading
        fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
        textAlign="center"
        mb={6}
        mt={12}
        position="relative"
        _hover={{
          "&::after": {
            width: "40%",
          },
        }}
        sx={{
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: "-5px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "20%",
            height: "4px",
            backgroundColor: "#f0c40d", // Neon color
            borderRadius: "2px",
            boxShadow: "0 0 8px #f0c40d, 0 0 15px #f0c40d", // Neon glow effect
            transition: "width 0.3s ease-in-out",
          },
        }}
      >
        Our Process
      </Heading> */}
      <Text
        textAlign="center"
        mb={12}
        fontSize={{ base: "sm", md: "md", lg: "lg" }}
      >
        At Explore B2B Lead, we employ a structured four-step process
        to ensure effective lead generation
      </Text>

      <Flex
        direction={{ base: "column", md: "row" }}
        align="center"
        justify="space-between"
        gap={{ base: 10, md: 5 }}
        py="10"
        bg="white"
        wrap="wrap"
        position="relative"
        px={{ base: 4, md: 20 }}
      >
        <ProcessPhase
          number="01"
          title="Data Research"
          color="#FBCCB2"
          description="We start by conducting thorough data research to identify potential leads. Using advanced tools and methodologies, we discover relevant contacts that fit your target market, ensuring a solid foundation for your outreach efforts."
        />
        <ProcessPhase
          number="02"
          title="Lead Scoring"
          color="#BFE3B3"
          description="Our team employs lead scoring techniques to prioritize leads based on their engagement and fit. By focusing on the right audience, we enhance the quality of leads, ensuring they align with your specific business objectives."
        />
        <ProcessPhase
          number="03"
          title="Lead Qualification"
          color="#7FFFD6"
          description="Our leads undergo rigorous quality checks to ensure accuracy. We carefully qualify each lead, evaluating their interest, budget and authority, ensuring that only the most promising leads are passed along for conversion."
        />
        <ProcessPhase
          number="04"
          title="Feedback & Improvement"
          color="#FFE6F2"
          description="After the lead qualification stage, we gather feedback from your sales team to evaluate lead performance. This continuous loop of feedback allows us to refine our strategies and improve our processes, ensuring better results with each campaign."
        />
      </Flex>

      {/* Get in Touch Section */}
      <Box display="flex" justifyContent="center" py={{ base: 10, md: 20 }}>
        <Box
          //bg="#f0c40d"
          //bg="#E9BD36"
          bg="#FDEF68"
          width={{ base: "80%", md: "70%", lg: "60%" }}
          px={10}
          py={8}
          borderTopLeftRadius={10}
          borderBottomLeftRadius={120}
          borderTopRightRadius={120}
          borderBottomRightRadius={10}
          boxShadow="md"
          // transition="transform 0.3s ease-in-out"
          // _hover={{ transform: "scale(1.05)", boxShadow: "xl" }}
        >
          <Flex
            direction={{ base: "column", md: "row" }}
            align="center"
            justify="space-around"
          >
            <Box textAlign={{ base: "center", md: "center" }} py={2}>
              <Heading fontSize={{ base: "xl", md: "3xl" }}>
                Get in Touch!
              </Heading>
            </Box>
            <Box py={2} w={{ base: "100%", md: "auto" }}>
              <Stack spacing={4}>
                <InputGroup boxShadow={"md"}>
                  <InputLeftAddon>Email</InputLeftAddon>
                  <Input
                    placeholder=""
                    bgColor="white"
                    w={{ base: "full", md: "md" }}
                    focusBorderColor="black"
                  />
                  <InputRightElement width="4.5rem" mr={2}>
                    <Button
                      h="1.75rem"
                      size="md"
                      bg="#FDEF68"
                      color="black"
                      _hover={{
                        bg: "black",
                        color: "#FDEF68",
                      }}
                    >
                      Submit
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Stack>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default OurProcess;
