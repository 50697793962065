import { Box, Text, Heading, Icon, Flex, Button } from "@chakra-ui/react";
import { useState } from "react";

const Gdpr = () => {
  const [show, setShow] = useState(false);

  return (
    <Box
      minH="120vh"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="#F1F1F1"
      position="relative"
      // mb={50}
    >
      <Heading
        fontSize={{ base: "2xl", md: "4xl" }}
        //mb={4}
        // filter="drop-shadow(1px 1px 8px #f0c40d)"
        bg="#F1F1F1"
        position="absolute"
        left="35%"
        top="5%"
      >
        We Are GDPR Compliant!
      </Heading>
      <Box position="absolute" top="9%"  ml="40px">
        <svg
          width="100%"
          height="50"
          viewBox="0 5 500 50"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 35 Q250 -15 500 30"
            fill="none"
            stroke="#f0c40d"
            strokeWidth="2"
          />
        </svg>
      </Box>
      <Box
        position="absolute"
        left={{ base: "10%", md: "0%" }}
        top="35%"
        w={{ base: "120px", md: "600px" }}
        h={{ base: "120px", md: "350px" }}
        //bg="#f0c40d"
        bg="#FDEF68"
        //borderRadius="full"
        zIndex={1}
      ></Box>

      <Flex
        direction={{ base: "column", md: "row" }}
        justify="space-between"
        align="center"
        zIndex={2}
        maxW="1200px"
        width="100%"
      >
        <Box flex="1" ml={{ base: 0, md: -90 }}>
          <Flex
            direction="column"
            gap={6}
            alignItems="center" // Center align cards
            position="relative"
          >
            <Flex
              direction={{ base: "column", md: "row" }}
              justifyContent="space-between"
              alignItems="center"
              //p={5}
            >
              <Box
                bg="white"
                p={15}
                boxShadow="2xl"
                borderRadius="md"
                flex="1"
                m={2} // Add margin for spacing
                position="relative" // Relative positioning
                minW={{ base: "80%", md: "50%" }} // Set min width for responsiveness
                //borderColor="#FDEF68" // Set border color to yellow
                //borderWidth="1px" // Set the border width
                transition="transform 0.3s ease-in-out" // Smooth transition
                _hover={{
                  transform: "scale(1.05)", // Slightly increase size on hover
                  boxShadow: "xl", // Increase shadow on hover for effect
                }}
              >
                <Heading
                  fontSize={{ base: "md", md: "lg" }}
                  mb={{ base: 0, md: 2 }}
                  textAlign={"start"}
                  //textDecoration="underline"
                  sx={{
                    textDecorationColor: "#FDEF68", // Set underline color to yellow
                    textDecorationThickness: "2px", // Set the width of the underline
                  }}
                  // filter="drop-shadow(1px 1px 8px #f0c40d)"
                >
                  Data Protection
                </Heading>
                <Text fontSize={{ base: "sm", md: "md" }} textAlign={"start"}>
                  Data protection involves implementing measures to prevent
                  unauthorized access to computers, websites, and databases. It
                  also safeguards against data loss or corruption, ensuring your
                  information remains secure.
                </Text>
              </Box>

              <Box
                bg="white"
                p={15}
                boxShadow="2xl"
                borderRadius="md"
                flex="1"
                m={2} // Add margin for spacing
                position="relative" // Relative positioning
                minW={{ base: "80%", md: "50%" }} // Set min width for responsiveness
                //borderColor="#FDEF68" // Set border color to yellow
                //borderWidth="1px" // Set the border width
                transition="transform 0.3s ease-in-out" // Smooth transition
                _hover={{
                  transform: "scale(1.05)", // Slightly increase size on hover
                  boxShadow: "xl", // Increase shadow on hover for effect
                }}
                mt={{ base: 5, md: 40 }} // Margin top for large screens
              >
                <Heading
                  fontSize={{ base: "md", md: "lg" }}
                  mb={{ base: 0, md: 2 }}
                  textAlign={"start"}
                  //textDecoration="underline"
                  sx={{
                    textDecorationColor: "#FDEF68", // Set underline color to yellow
                    textDecorationThickness: "2px", // Set the width of the underline
                  }}
                  // filter="drop-shadow(1px 1px 8px #f0c40d)"
                >
                  Privacy
                </Heading>
                <Text fontSize={{ base: "sm", md: "md" }} textAlign={"start"}>
                  Data privacy, or information privacy, refers to an
                  organization’s ability to control what data within its
                  computer systems can be shared with third parties. It ensures
                  that sensitive information is protected and used responsibly.
                </Text>
              </Box>
            </Flex>

            <Flex
              direction={{ base: "column", md: "row" }} // Column for mobile, row for medium and above
              justifyContent="space-between"
              alignItems="center"
              //p={5}
              mt={{ base: 0, md: -150 }}
            >
              <Box
                bg="white"
                p={15}
                boxShadow="2xl"
                borderRadius="md"
                flex="1"
                m={2} // Add margin for spacing
                position="relative" // Relative positioning
                minW={{ base: "80%", md: "50%" }} // Set min width for responsiveness
                //borderColor="#FDEF68" // Set border color to yellow
                //borderWidth="1px" // Set the border width
                transition="transform 0.3s ease-in-out" // Smooth transition
                _hover={{
                  transform: "scale(1.05)", // Slightly increase size on hover
                  boxShadow: "xl", // Increase shadow on hover for effect
                }}
              >
                <Heading
                  fontSize={{ base: "md", md: "lg" }}
                  mb={{ base: 0, md: 2 }}
                  textAlign={"start"}
                  //textDecoration="underline"
                  sx={{
                    textDecorationColor: "#FDEF68", // Set underline color to yellow
                    textDecorationThickness: "2px", // Set the width of the underline
                  }}
                  // filter="drop-shadow(1px 1px 8px #f0c40d)"
                >
                  Information Security
                </Heading>
                <Text fontSize={{ base: "sm", md: "md" }} textAlign={"start"}>
                  Information security is built on three core principles:
                  confidentiality, integrity, and availability. We implement a
                  range of solutions designed to protect vital company
                  information from alteration, disruption, destruction, and
                  unauthorized access.
                </Text>
              </Box>

              <Box
                bg="white"
                p={15}
                boxShadow="2xl"
                borderRadius="md"
                flex="1"
                m={2} // Add margin for spacing
                position="relative" // Relative positioning
                minW={{ base: "80%", md: "50%" }} // Set min width for responsiveness
                //borderColor="#FDEF68" // Set border color to yellow
                //borderWidth="1px" // Set the border width
                transition="transform 0.3s ease-in-out" // Smooth transition
                _hover={{
                  transform: "scale(1.05)", // Slightly increase size on hover
                  boxShadow: "xl", // Increase shadow on hover for effect
                }}
                mt={{ base: 5, md: 40 }} // Margin top for large screens
                //mb={{ base: 5, md: 40 }}
              >
                <Heading
                  fontSize={{ base: "md", md: "lg" }}
                  mb={{ base: 0, md: 2 }}
                  textAlign={"start"}
                  //textDecoration="underline"
                  sx={{
                    textDecorationColor: "#FDEF68", // Set underline color to yellow
                    textDecorationThickness: "2px", // Set the width of the underline
                  }}
                  // filter="drop-shadow(1px 1px 8px #f0c40d)"
                >
                  Transparency
                </Heading>
                <Text fontSize={{ base: "sm", md: "md" }} textAlign={"start"}>
                  We handle data with integrity and in compliance with the law.
                  We are committed to informing individuals and businesses about
                  the data we collect, who has access to it, how it’s used, and
                  how they can engage with it. Your trust is our priority.
                </Text>
              </Box>
            </Flex>
          </Flex>
        </Box>

        <Box
          flex="1"
          textAlign={{ base: "center", md: "left" }}
          mt={{ base: 10, md: 0 }}
          ml={{ base: 10, md: 20 }}
          px={{ base: 0, md: 10 }}
          position="relative"
        >
          {/* <Heading
            fontSize={{ base: "2xl", md: "4xl" }}
            mb={4}
            // filter="drop-shadow(1px 1px 8px #f0c40d)"
          >
            We Are GDPR Compliant!
          </Heading> */}
          <Text
            fontSize={{ base: "md", md: "lg" }}
            color="gray.600"
            mb={4}
            textAlign={"justify"}
          >
            At Explore B2B Lead, we adhere strictly to the General Data Protection
            Regulation (GDPR) guidelines. Our data processing practices for B2B
            communications and marketing programs are designed solely for
            business purposes. We maintain essential business data on EU
            citizens exclusively to support our B2B marketing initiatives. Our
            primary focus is on connecting with businesses, not individuals.
            Your privacy and compliance are our top priorities!
          </Text>

          {show && (
            <Text
              fontSize={{ base: "md", md: "lg" }}
              color="gray.600"
              mb={4}
              textAlign={"justify"}
            >
              At Explore B2B Lead, we prioritize quality assurance in our lead
              generation process to ensure that every lead meets the highest
              standards before delivery. Our rigorous approach involves multiple
              layers of quality checks, including thorough validation of contact
              information, relevance to client criteria, and compliance with
              industry regulations. Each lead undergoes a detailed assessment to
              verify its accuracy and legitimacy, allowing us to provide our
              clients with reliable, high-quality leads. By maintaining strict
              quality control, we help our clients achieve better engagement,
              higher conversion rates, and a strong return on their investment.
              Your success is our priority, and we’re committed to delivering
              leads that truly drive results.
            </Text>
          )}
          {show ? (
            <Button
              mt={8}
              width="100%"
              bg="black"
              color="#f0c40d"
              size="lg"
              _hover={{ bg: "yellow.500", color: "black" }}
              onClick={() => setShow(false)}
            >
              Read Less
            </Button>
          ) : (
            <Button
              mt={8}
              width="100%"
              bg="black"
              color="#f0c40d"
              size="lg"
              _hover={{ bg: "yellow.500", color: "black" }}
              onClick={() => setShow(true)}
            >
              Read More
            </Button>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default Gdpr;
