import { Box, SimpleGrid, Heading, Text, Flex, Image } from "@chakra-ui/react";
import {
  FaCode,
  FaBullhorn,
  FaLaptop,
  FaChartLine,
  FaLightbulb,
} from "react-icons/fa";
import { MdMarkEmailRead } from "react-icons/md";
import services_bg from "../../assets/services_bg4.jpg";
import db from "../../assets/big-data.gif";
import lead from "../../assets/employee.gif";
import cs from "../../assets/content-media.gif";
import abm from "../../assets/abm.gif";
import qualification from "../../assets/filter.png";
import email from "../../assets/mail.gif";
import "../../App.css";

const Services = () => {
 

  const services = [
    {
      icon: FaLaptop,
      image: db,
      title: "Our Database",
      description: `Data Solutions provide an organized, compliant, and comprehensive database with standardized prospects and detailed company information across North America, LATAM, Europe, ANZ, the Nordics, Middle East, Africa, Asia and APAC regions. We deliver current, authorized B2B leads to help you efficiently connect with relevant decision-makers at the right time. Our advanced search features, B2B data cleansing, and audience profiling services ensure precise, up-to-date information by merging duplicates and correcting data. This optimized data enhances customer insights, streamlines targeting and boosts sales conversion rates.`,
      highlight: `We deliver current, authorized B2B leads to help you efficiently connect with relevant decision-makers at the right time.`,
    },
    {
      icon: FaBullhorn,
      image: lead,
      title: "Lead Generation",
      description: `Our lead generation services provide tailored, high-quality leads to fill your sales funnel. With advanced data, multi-channel capabilities, and lead scoring, your sales teams engage effectively. Through rigorous quality assurance, you can evaluate audience interest and map prospects based on intent. Our marketing and sales-ready leads support relationship-building in prospecting and nurturing. We offer Content Syndication, white paper promotions, MQLs, SQLs, BANT, and ABM strategies to boost ROI. Our data-driven insights empower smarter decision-making and campaign optimization for better outcomes.`,
      highlight: `We offer Content Syndication, white paper promotions, MQLs, SQLs, BANT, and ABM strategies to boost ROI.`,
    },
    {
      icon: FaCode,
      image: cs,
      title: "Content Syndication",
      description: `At Explore B2b Lead, we strategically distribute your content to attract and capture potential leads, benefiting our B2B clients demand generation services. Our service targets an audience actively seeking information about your products or services, leveraging our comprehensive network and expertise to ensure your content reaches the right prospects. This approach significantly enhances brand visibility, drives meaningful engagement, broadens your reach, and increases both the quality and quantity of leads generated to effectively support your sales and marketing goals.`,
      highlight: `Our service targets an audience actively seeking information about your products or services, leveraging our comprehensive network and expertise to ensure your content reaches the right prospects.`,
    },
    {
      icon: FaLightbulb,
      image: abm,
      title: "Account Based Marketing",
      description: `Our specialty lies in B2B marketing, where our sales and marketing teams collaborate to convert best-fit accounts into clients. Our custom database, based on your ABM list and marketing team's expertise, identifies, engages and delivers quality leads to enhance marketing ROI, attributed revenue and conversions. Using the ABM technique, we identify the target audience, craft a custom audience and deliver qualified leads, aligning sales and marketing efforts. This collaborative approach ensures tailored engagement strategies that resonate with your prospects, maximizing conversion opportunities.`,
      highlight: `Our custom database, based on your ABM list and marketing team's expertise, identifies, engages and delivers quality leads to enhance marketing ROI, attributed revenue and conversions.`,
    },
    // {
    //   icon: FaChartLine,
    //   image: qualification,
    //   title: "Lead Qualification",
    //   description: `All our leads undergo a rigorous quality assurance procedure before entering your channel. Our lead preference function allows you to evaluate audience interest and prospects are carefully mapped based on their intent. Our marketing and sales-ready leads help maintain relationships established during the prospecting and nurturing phases. Telemarketing leads are reviewed by quality team for accuracy, intent, and compliance with the campaign scope, undergoing two layers of quality audits to ensure they meet client requirements. With these stringent quality measures, we provide high-quality, compliant and relevant leads tailored to your campaign needs.`,
    //   highlight: `Our marketing and sales-ready leads help maintain relationships established during the prospecting and nurturing phases.`,
    // },
    {
      icon: FaChartLine,
      image: qualification,
      title: "BANT",
      description: `BANT (Budget, Authority, Need, and Timeline) leads are pivotal for driving successful demand generation, providing a structured framework to identify high-quality prospects. We specialize in delivering BANT-qualified leads that seamlessly align with your campaign objectives. Our team of experts conducts comprehensive research and strategic outreach to engage prospects that perfectly match your target profile. By thoroughly validating each lead to ensure it meets your specific requirements, we empower your sales team to focus on closing deals with decision-makers who are ready to act, giving you a decisive edge in the market.`,
      highlight: `We specialize in delivering BANT-qualified leads that seamlessly align with your campaign objectives.`,
    },
    {
      icon: MdMarkEmailRead,
      image: email,
      title: "Email Marketing",
      description: `Our email marketing service is customized to drive targeted traffic to optimized landing pages, utilizing compelling content and clear CTAs to encourage effective lead generation. We prioritize double opt-in capabilities for high-quality leads and data protection compliance, complemented by thorough performance tracking and analysis to enhance campaign effectiveness. Our approach includes utilizing targeted email campaigns to direct potential customers to landing pages, crafting engaging content and CTAs, optimizing landing page design and conducting performance tracking and analysis for continual improvement and refinement of campaign strategies.`,
      highlight: `We prioritize double opt-in capabilities for high-quality leads and data protection compliance, complemented by thorough performance tracking and analysis to enhance campaign effectiveness.`,
    },
  ];

  return (
    <Box position="relative" pb={20}>
      {/* <Heading
        fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
        textAlign="center"
        mb={6}
        mt={12}
        // filter="drop-shadow(1px 1px 8px #f0c40d)"
      >
        Our Services
      </Heading> */}
      <Heading fontSize={{ base: "2xl", md: "3xl", lg: "5xl" }} mb={6}
        mt={12}>
      Our Services
      </Heading>
      <Box position="relative" mt="-30px" ml="40px">
        <svg
          width="95%"
          height="50"
          viewBox="0 5 400 80"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 35 Q250 -15 500 30"
            fill="none"
            stroke="#f0c40d"
            strokeWidth="3"
          />
        </svg>
      </Box>
      {/* <Heading
        fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
        textAlign="center"
        mb={6}
        mt={12}
        position="relative"
        _hover={{
          "&::after": {
            transform: "scaleX(1)",
          },
        }}
        sx={{
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: "-5px",
            left: "40%",
            transform: "translateX(-50%) scaleX(0)",
            width: "20%",
            height: "4px",
            backgroundColor: "#FDEF68", // Neon color
            borderRadius: "2px",
            boxShadow: "0 0 8px #FDEF68, 0 0 15px #f0c40d",
            transformOrigin: "center",
            transition: "transform 0.3s ease-in-out",
          },
        }}
      >
        Our Services
      </Heading> */}
      {/* <Heading
        fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
        textAlign="center"
        mb={6}
        mt={12}
        position="relative"
        _hover={{
          "&::after": {
            width: "40%",
          },
        }}
        sx={{
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: "-5px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "20%",
            height: "4px",
            backgroundColor: "#f0c40d", // Neon color
            borderRadius: "2px",
            boxShadow: "0 0 8px #f0c40d, 0 0 15px #f0c40d", // Neon glow effect
            transition: "width 0.3s ease-in-out",
          },
        }}
      >
        Our Services
      </Heading> */}

      <Box
        bgImage={`url(${services_bg})`}
        bgSize="cover"
        bgPosition="center"
        height={{ base: "40vh", md: "60vh" }} 
        position="relative"
        zIndex={0}
      />

      <Box
        // bg="#f0c40d"
        bg="#F1F1F1"
        height="auto"
        width="100%"
        py={16} // Padding to create space between background image and content
        position="relative"
        zIndex={1}
      >
        {/* <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }} // Responsive columns
          spacing={8}
          px={{ base: 4, md: 20 }}
        >
          {services2.map((service, index) => (
            <Box
              key={index}
              //bg="white"
              //bg="#ece75f"
              //bg="#E9BD36"
              bg="#FDEF68"
              p={6}
              shadow="2xl"
              boxShadow="2xl"
              borderWidth="1px"
              borderRadius="3xl"
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              position="relative"
              minH="30vh"
              transition="transform 0.3s ease-in-out"
              _hover={{
                transform: "scale(1.10)",
                boxShadow: "2xl",
                shadow: "2xl",
                bg: "white",
              }}
              role="group" // Enable group hover state
            >
              <Flex
                direction="row" // Arrange icon and heading in a row
                alignItems="center"
                mb={4}
                width="100%" // Make sure the flex container spans the width of the card
              >
                <Box
                  bg="white"
                  p={3}
                  borderRadius="full"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mr={4}
                  flexShrink={0}
                  _groupHover={{ bg: "#FDEF68" }}
                  // role="group" // Enable group hover state
                >
                  <Image
                    src={service.image}
                    w={{ base: 6, md: 8 }}
                    h={{ base: 6, md: 8 }}
                    alt="custom"
                    boxSize="50px"
                    objectFit="cover"
                    mixBlendMode="multiply"
                    transition="transform 0.3s ease-in-out"
                    _groupHover={{ transform: "rotate(360deg)" }} // Rotate image on hover
                  />
                </Box>

                <Heading fontSize={{ base: "lg", md: "xl" }} mb={0} isTruncated>
                  {service.title}
                </Heading>
              </Flex>

              <Text
                mb={4}
                fontSize={{ base: "sm", md: "sm" }}
                noOfLines={{ base: 8, md: 12 }}
                textAlign="justify"
                _groupHover={{ noOfLines: 14, fontsize: "xl" }}
              >
                {service.description}
              </Text>
            </Box>
          ))}
        </SimpleGrid> */}

        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          spacing={8}
          px={{ base: 4, md: 20 }}
        >
          {services.map((service, index) => (
            <Box
              key={index}
              bg="#FDEF68"
              p={6}
              shadow="2xl"
              borderWidth="1px"
              borderRadius="3xl"
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              position="relative"
              minH="30vh"
              transition="transform 0.3s ease-in-out"
              _hover={{
                transform: "scale(1.10)",
                bg: "white",
              }}
              role="group"
            >
              <Flex direction="row" alignItems="center" mb={4} width="100%">
                <Box
                  bg="white"
                  p={3}
                  borderRadius="full"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mr={4}
                  flexShrink={0}
                  _groupHover={{ bg: "#FDEF68" }}
                >
                  <Image
                    src={service.image}
                    w={{ base: 6, md: 8 }}
                    h={{ base: 6, md: 8 }}
                    alt="custom"
                    boxSize="50px"
                    objectFit="cover"
                    mixBlendMode="multiply"
                    transition="transform 0.3s ease-in-out"
                    _groupHover={{ transform: "rotate(360deg)" }}
                  />
                </Box>
                <Heading fontSize={{ base: "lg", md: "xl" }} mb={0} isTruncated>
                  {service.title}
                </Heading>
              </Flex>

              <Text
                mb={4}
                fontSize={{ base: "sm", md: "sm" }}
                textAlign="justify"
              >
                {service.description.split(service.highlight)[0]}
                <Text
                  as="span"
                  //bg="yellow.100"
                  //p={1}
                  borderRadius="md"
                  //fontWeight="bold"
                  //boxShadow="md"
                  display="inline"
                  transition="all 0.3s ease-in-out"
                  _groupHover={{
                    bg: "#FDEF68",
                    boxShadow: "lg",
                    fontWeight:"bold"
                  }}
                >
                  {service.highlight}
                </Text>
                {service.description.split(service.highlight)[1]}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default Services;
