import {
  Box,
  Heading,
  Image,
  SimpleGrid,
  Text,
  Icon,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaLaptop, FaRegSmileBeam, FaTeamspeak } from "react-icons/fa";
import { SiStackoverflow } from "react-icons/si";
import { MdOutlineLeaderboard } from "react-icons/md";
import { PiMicrosoftTeamsLogoLight } from "react-icons/pi";
import alteryx from "../../assets/alteryx.jpg";
import databricks from "../../assets/databricks.jpg";
import lenovo from "../../assets/lenovo.jpg";
import dell from "../../assets/dell.jpg";
import tmobile from "../../assets/tmobile.jpg";
import aveva from "../../assets/aveva.jpg";
import microsoft from "../../assets/microsoft.jpg";
import aws from "../../assets/aws.jpg";
import hsbc from "../../assets/hsbc.jpg";
import siemens from "../../assets/siemens3.png";
import hcl from "../../assets/hcl2.png";
import paloalto from "../../assets/paloalto.jpg";
import unisys from "../../assets/unisys.jpg";
import equinix from "../../assets/equinix.jpg";
import veeam from "../../assets/veeam.jpg";
import messagebird from "../../assets/messagebird.jpg";
import omada from "../../assets/omada.jpg";
import safesoftware from "../../assets/safesoftware.jpg";
import blackline from "../../assets/blackline.jpg";
import cloudflare from "../../assets/cloudflare.jpg";
import radware from "../../assets/radware.jpg";
import dremio from "../../assets/dremio.jpg";
import about1 from "../../assets/about1.jpeg";
import about2 from "../../assets/about2.jpg";
import about3 from "../../assets/about3.jpg";
import a1 from "../../assets/abstract-shape.gif";
import a2 from "../../assets/abstract-shape1.gif";
import g1 from "../../assets/grad1.gif";
import g2 from "../../assets/g2.gif";
import g3 from "../../assets/g3.gif";
import g4 from "../../assets/g4.gif";
import g5 from "../../assets/g5.gif";
import g6 from "../../assets/g6.gif";
import collage from "../../assets/collage2.jpg";

const AboutUs = () => {
  const icons = [
    { icon: FaLaptop, text: "Year's Of Experience", number: 4 },
    { icon: FaRegSmileBeam, text: "Happy Clients", number: 8 },
    { icon: SiStackoverflow, text: "Leads per month", number: 1200  },
    { icon: PiMicrosoftTeamsLogoLight, text: "Dedicated Team", number: 30 },
  ];

  const icons2 = [
    { image: databricks, text: "Data Bricks" },
    { image: lenovo, text: "Lenovo" },
    { image: aveva, text: "Aveva" },
    { image: hsbc, text: "HSBC" },
    { image: dell, text: "Dell" },
    { image: microsoft, text: "Microsoft" },
    { image: aws, text: "AWS" },
    { image: equinix, text: "Equinix" },
    { image: hcl, text: "HCL" },
    { image: unisys, text: "Unisys" },

    { image: siemens, text: "Siemens" },
    { image: alteryx, text: "Alteryx" },
    // {image:paloalto, text: "Paloalto"},
    // {image:unisys, text: "Unisys"},
    // {image:veeam, text: "Veeam"},
    //{image:tmobile, text: "T-Mobile"},
    // {image:messagebird, text: "MessageBird"},
    // {image:alteryx, text: "Alteryx"},
    // {image:omada, text: "Omada"},
    // {image:safesoftware, text: "Safe Software"},
    // {image:alteryx, text: "Ping Identity"},
    // {image:blackline, text: "BlackLine"},
    // {image:cloudflare, text: "Cloudflare"},
    // {image:radware, text: "Radware"},
    // {image:dremio, text: "Dremio"},
  ];

  return (
    <Box
      bgColor="white"
      bgPosition="center"
      mt={{ base: "200px", md: "120px" }}
      py={20}
      px={{ base: 4, md: 8, lg: 16 }} // Padding for different screen sizes
      textAlign="center" // Center-align the text
    >
      <Heading fontSize={{ base: "2xl", md: "3xl", lg: "5xl" }}>
        About Us
      </Heading>
      <Box position="relative" mt="-5px" ml="40px">
        <svg
          width="95%"
          height="50"
          viewBox="0 5 400 80"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 35 Q250 -15 500 30"
            fill="none"
            stroke="#f0c40d"
            strokeWidth="3"
          />
        </svg>
      </Box>
      {/* <Heading
        fontSize={{ base: "2xl", md: "3xl", lg: "5xl" }}
        position="relative"
      >
        About Us
      </Heading>
      <Box position="relative" mt="-10px" ml="10px">
        <svg
          width="100%"
          height="50"
          viewBox="0 0 500 50"
          xmlns="http://www.w3.org/2000/svg"
          style={{ transform: "rotate(-190deg)", transformOrigin: "0 25" }}
        >
          <path
            d="M0 25 Q250 0 500 25"
            fill="none"
            stroke="#f0c40d"
            strokeWidth="2"
            strokeLinecap="round"
            vectorEffect="non-scaling-stroke"
          >
            <animate
              attributeName="stroke-width"
              from="2"
              to="5"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </Box> */}

      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection={{ base: "column", md: "row" }}
        gap={8}
        width={{ base: "90%", md: "80%", lg: "80%" }}
        mx="auto" // Center the entire Flex container
      >
        {/* Text Section */}
        <Box flex="1" maxW={{ base: "100%", md: "50%" }}>
          {/* <Heading
            fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }} // Responsive font size
            mb={6}

          >
            About Us
          </Heading> */}
          {/* <Heading
            fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
            textAlign="center"
            mb={6}
            mt={12}
            position="relative"
            _hover={{
              "&::after": {
                transform: "scaleX(1)",
              },
            }}
            sx={{
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "-5px",
                left: "30%",
                transform: "translateX(-50%) scaleX(0)",
                width: "38%",
                height: "4px",
                backgroundColor: "#FDEF68", // Neon color
                borderRadius: "2px",
                boxShadow: "0 0 8px #FDEF68, 0 0 15px #f0c40d",
                transformOrigin: "center",
                transition: "transform 0.3s ease-in-out",
              },
            }}
          >
            About Us
          </Heading> */}
          {/* <Heading
            fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
            textAlign="center"
            mb={6}
            mt={12}
            position="relative"
            _hover={{
              "&::after": {
                width: "80%",
              },
            }}
            sx={{
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "-5px",
                left: "50%",
                transform: "translateX(-50%)",
                width: "50%",
                height: "4px",
                backgroundColor: "#f0c40d", // Neon color
                borderRadius: "2px",
                boxShadow: "0 0 8px #f0c40d, 0 0 15px #f0c40d", // Neon glow effect
                transition: "width 0.3s ease-in-out",
              },
            }}
          >
            About Us
          </Heading> */}
          {/* <Box textAlign="center" mt={12}>
            <Heading
              fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
              textAlign="center"
              mb={6}
              position="relative"
              _hover={{
                "&::after": {
                  width: "80%",
                },
              }}
              sx={{
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: "-5px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "50%",
                  height: "4px",
                  backgroundColor: "#f0c40d", // Neon color
                  borderRadius: "2px",
                  boxShadow: "0 0 8px #f0c40d, 0 0 15px #f0c40d", // Neon glow effect
                  transition: "width 0.3s ease-in-out",
                },
              }}
            >
              About Us
            </Heading>

           
            <Box mt={4}>
              <svg
                width="100%"
                height="50"
                viewBox="0 0 500 50"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 25 Q250 0 500 25"
                  fill="none"
                  stroke="#f0c40d"
                  strokeWidth="3"
                />
              </svg>
            </Box>
          </Box> */}
          <Text
            fontSize={{ base: "md", md: "lg" }}
            //lineHeight={{ base: "tall", md: "short" }}
            textAlign="justify"
            mb={4}
          >
            At Explore B2B Lead, we specialize in transforming your
            business through targeted B2B demand and lead generation services.
            Our mission is to empower organizations with expertly crafted
            solutions that drive real, measurable results.
          </Text>

          <Text
            fontSize={{ base: "md", md: "lg" }}
            //lineHeight={{ base: "tall", md: "short" }}
            textAlign="justify"
            mb={4}
          >
            We believe in the power of quality over quantity. Our meticulously
            designed lead generation strategies focus on delivering high-value
            leads that align perfectly with your business goals. By precisely
            targeting the right audience, we ensure that every lead we generate
            is a valuable asset to your sales team.
          </Text>

          <Text
            fontSize={{ base: "md", md: "lg" }}
            //lineHeight={{ base: "tall", md: "short",}}
            textAlign="justify"
          >
            Our approach seamlessly transitions potential leads into your sales
            pipeline, maximizing your return on investment (ROI). With a
            commitment to excellence, we provide comprehensive lead generation
            services that guarantee exceptional sales leads and outstanding
            email deliverability. This not only enhances the effectiveness of
            your outbound marketing campaigns but also propels your business to
            new heights.
          </Text>
        </Box>

        {/* Image Section */}
        <Box
          flex="1"
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="relative"
        >
          <Image
            src={collage}
            width={{ base: "300px", md: "350px", lg: "450px" }}
            height={{ base: "400px", md: "500px", lg: "500px" }}
            borderRadius="20px"
            // objectFit="cover"
            mb={4}
          />
          {/* <Box
            position="absolute"
            left={{ base: "5%", md: "30%" }}
            bottom="30%"
            w={{ base: "70px", md: "100px" }}
            h={{ base: "70px", md: "100px" }}
            //bg="#f0c40d"
            borderRadius="full"
            zIndex={1}
          >
            <Image
              src={g6}
              position={"absolute"}
              borderRadius="20px"
              objectFit="cover"
              mb={4}
              // width={{ base: "20px", md: "20px", lg: "50px" }}
              // height={{ base: "20px", md: "20px", lg: "50px" }}
            />
          </Box> */}
          {/* <Box
            position="absolute"
            left={{ base: "5%", md: "5%" }}
            bottom="10%"
            w={{ base: "70px", md: "100px" }}
            h={{ base: "70px", md: "100px" }}
            //bg="#f0c40d"
            borderRadius="full"
            zIndex={1}
          >
            <Image
              src={g2}
              position={"absolute"}
              borderRadius="20px"
              objectFit="cover"
              mb={4}
              
            />
            <Image
              src={g5}
              position={"absolute"}
              borderRadius="20px"
              objectFit="cover"
              mb={4}
              
            />
          </Box> */}

          {/* <Image
            src={about3}
            width={{ base: "200px", md: "350px", lg: "400px" }}
            height={{ base: "200px", md: "250px", lg: "250px" }}
            ml={{ base: 0, md: "250px", lg: "500px" }}
            borderRadius="20px"
            objectFit="cover"
          /> */}
          {/* <Box
            position="absolute"
            left={{ base: "5%", md: "100%" }}
            bottom="100%"
            w={{ base: "70px", md: "80px" }}
            h={{ base: "70px", md: "80px" }}
            borderRadius="full"
            zIndex={1}
          >
            <Image
              src={g1}
              position={"absolute"}
              borderRadius="20px"
              objectFit="cover"
              mb={4}
            />
          </Box>
          <Box
            position="absolute"
            left={{ base: "5%", md: "98%" }}
            bottom="98%"
            w={{ base: "70px", md: "80px" }}
            h={{ base: "70px", md: "80px" }}
            borderRadius="full"
            zIndex={1}
          >
            <Image
              src={g5}
              position={"absolute"}
              borderRadius="20px"
              objectFit="cover"
              mb={4}
            />
          </Box> */}
          {/* <Box
            position="absolute"
            left={{ base: "5%", md: "90%" }}
            bottom="50%"
            w={{ base: "70px", md: "80px" }}
            h={{ base: "70px", md: "80px" }}
            borderRadius="full"
            zIndex={1}
          >
            <Image
              src={g3}
              position={"absolute"}
              borderRadius="20px"
              objectFit="cover"
              mb={4}
            />
          </Box> */}
        </Box>
      </Flex>

      {/* Icons Section */}
      <Box display="flex" justifyContent="center" mb={12}>
        <Box width={{ base: "90%", md: "80%", lg: "90%" }} borderRadius="md">
          <Heading
            textAlign="center"
            mt={40}
            mb={20}
            fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
          >
            Driving Growth With Targeted <span style={{color:"#FFB600"}}>B2B Lead Generation</span> 
          </Heading>
          <SimpleGrid
            columns={{ base: 2, sm: 2, md: 4 }}
            spacing={2}
            alignItems="center"
            justifyItems="center"
          >
            {icons.map((item, index) => (
              <Box
                key={index}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                {/* Icon inside rounded box */}
                <Box
                  p={5}
                  borderRadius="full"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width={{ base: "60px", md: "80px" }}
                  height={{ base: "60px", md: "80px" }}
                >
                  {item.icon ? (
                    <Icon
                      as={item.icon}
                      w={{ base: 10, md: 24 }}
                      h={{ base: 10, md: 24 }}
                      //color="#f0c40d"
                      color="#FDEF68"
                      //transition="transform 0.3s ease-in-out"
                      _hover={{ transform: "scale(1.05)" }}
                    />
                  ) : (
                    <Image
                      src={item.image}
                      alt="custom"
                      boxSize="40px"
                      objectFit="cover"
                      borderRadius="full"
                    />
                  )}
                </Box>
               
                <AnimatedCounterPlus endNumber={item.number === 1200 ? `${item.number}+` : item.number} />

                <Text
                  fontSize={{ base: "sm", md: "md" }}
                  fontWeight="bold"
                  mt={2}
                  // filter="drop-shadow(1px 1px 8px #f0c40d)"
                >
                  {item.text}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      </Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
        alignContent={"center"}
        //width={{ base: "90%", md: "80%", lg: "70%" }}
        bg={"whiteAlpha.500"}
        boxShadow={"2xl"}
        borderRadius="3xl"
        mt={40}
      >
        <Box>
          <Heading
            justifyContent="center"
            alignContent={"center"}
            textAlign={{ base: "center", md: "start" }}
            marginLeft={12}
            fontSize={{ base: "md", md: "xl", lg: "4xl" }}
            textDecorationLine={"underline"}
            textDecorationColor={"#FDEF68"}
          >
            Impactful Campaigns Delivered For
          </Heading>
          <Heading
            justifyContent="center"
            alignContent={"center"}
            textAlign={{ base: "center", md: "start" }}
            marginLeft={12}
            fontSize={{ base: "md", md: "xl", lg: "4xl" }}
            textDecorationLine={"underline"}
            textDecorationColor={"#FDEF68"}
          >
            Industry Leaders
          </Heading>
        </Box>
        <Box
          width={{ base: "90%", md: "80%", lg: "70%" }}
          py={4}
          borderRadius="md"
          //border={"1px solid black"}
        >
          <SimpleGrid
            columns={{ base: 3, sm: 2, md: 4 }}
            spacing={1}
            alignItems="center"
            justifyItems="center"
          >
            {icons2.map((item, index) => (
              <Box
                key={index}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                {/* Icon inside rounded box */}
                <Box
                  p={4}
                  //borderRadius="full"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width={{ base: "80px", md: "100px" }}
                  height={{ base: "80px", md: "100px" }}
                >
                  <Image
                    src={item.image}
                    alt="custom"
                    //boxSize="100px"
                    objectFit="cover"
                    // mixBlendMode={"darken"}
                    transition="transform 0.3s ease-in-out"
                    //_hover={{ transform: "scale(1.05)", boxShadow: "xl" }}
                  />
                </Box>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      </Flex>
    </Box>
  );
};



const AnimatedCounterPlus = ({ endNumber }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Extract numeric value from endNumber
    const targetNumber = typeof endNumber === "string" && endNumber.includes("+")
      ? parseInt(endNumber, 10)
      : endNumber;

    let start = 0;
    const duration = 2000; // Duration of the counter animation (in milliseconds)
    const stepTime = Math.abs(Math.floor(duration / targetNumber)); // Calculate time per step

    const timer = setInterval(() => {
      start += 1;
      setCount(start);
      if (start === targetNumber) {
        clearInterval(timer);
      }
    }, stepTime);

    return () => {
      clearInterval(timer);
    };
  }, [endNumber]);

  // Display count with "+" if endNumber contains "+"
  return (
    <Text
      mt={4}
      fontSize={{ base: "lg", md: "3xl" }}
      fontWeight="bold"
      color="black"
    >
      {count}{typeof endNumber === "string" && endNumber.includes("+") ? "+" : ""}
    </Text>
  );
};


export default AboutUs;
